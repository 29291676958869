import axios from "axios";
const API = "https://api.uzbcargo.uz/api"

const mainURL = axios.create({
    baseURL: API,
});

mainURL.interceptors.request.use((req) => {
    let token = localStorage.getItem("token")
    if (token) {
        req.headers.authorization = `Bearer ${token}`;
    }
    return req;
});

export default mainURL;