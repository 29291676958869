import React, { useEffect, useState } from 'react'
import "./UsersId.scss"
import axios from '../../../api'

const API = "https://api.uzbcargo.uz/api"

function UsersId() {
    const [data, setData] = useState([])
    const [userId, setUserId] = useState(0)
    const [showBigImg, setShowBigImg] = useState(false)
    useEffect(() => {
        axios.get(`/identificators`)
            .then(res => {
                setData(res.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    let findUser = data?.find(el => el.id === +userId)

    let user = data?.map((el) => (
        <div onClick={(e) => setUserId(e.target.id)} key={el.id} className='user__card'>
            <div className="user__card__frame">
                <img onClick={() => setShowBigImg(true)} id={el.id} src={`https://api.uzbcargo.uz/api/${el.file_id.location}`} alt="" />
            </div>
            <div className="user__card__content">
                <h1>{el.first_name} {el.last_name}</h1>
                <h2>tel-1:{el.phone_number}</h2>
                <h2>tel-2:{el.second_number}</h2>
                <p>{el.region}</p>
                <h3>ID: {el.id_number}</h3>
            </div>
        </div>
    ))

    return (
        <div className='user__wrapper'>
            <div onClick={() => setShowBigImg(false)} className={`overlay__image__module ${showBigImg ? "show__big__img" : ""}`}></div>
            <div className={`image__module ${showBigImg ? "show__big__img" : ""}`}>
                <img src={`https://api.uzbcargo.uz/api/${findUser?.file_id.location}`} alt="" />
            </div>
            {user}
        </div>
    )
}

export default UsersId