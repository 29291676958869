import React, { useState } from 'react';
import './Home.scss';

import { GoGift } from 'react-icons/go';
import { FaInstagram, FaPhone, FaTelegram, FaTicket, FaXmark } from 'react-icons/fa6';
import { TiTick } from "react-icons/ti";
import { IoAirplaneSharp } from "react-icons/io5";
import { PiTruckLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";

import LoadingAnim from '../../components/loading-anim/LoadingAnim';
import axios from 'axios';
import { CITY_ITEMS } from '../../static';
import { toast } from 'react-toastify';

const API = 'https://api.uzbcargo.uz/api';

let getIdInitial = {
  firstName: '',
  lastName: '',
  region: '',
  fileId: '',
  id: '',
};

let complaintInitial = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  idNumber: '',
  text: '',
};

function Home() {
  const [idModule, setIdModule] = useState(false);
  const [complaintModule, setComplaintModule] = useState(false);
  const [priceModule, setPriceModule] = useState(false);
  const [branchModule, setBranchModule] = useState(false);
  const [showId, setShowId] = useState(false);
  const [checkUpload, setCheckUpload] = useState(false);

  const [getUserId, setGetUserId] = useState(getIdInitial);
  const [complaint, setComplaint] = useState(complaintInitial);

  let [phoneNumber, setPhoneNumber] = useState('');
  let [secondNumber, setSecondNumber] = useState('');
  let [complaintPhoneMumber, setComplaintPhoneMumber] = useState('');
  let [fileId, setFileId] = useState(null);

  const [fileData, setFileData] = useState([]);
  const [userData, setUserData] = useState([]);

  const handleFileChange = (event) => {
    setFileId(event.target.files[0]);
  };

  const handleComplaintSubmit = (e) => {
    e.preventDefault();
    let newComplaintNumber;
    if (complaintPhoneMumber) {
      newComplaintNumber = complaintPhoneMumber = '998' + +complaintPhoneMumber;
    } else {
      newComplaintNumber = 0;
    }
    let newComplaint = {
      first_name: complaint.firstName,
      last_name: complaint.lastName,
      phone_number: +newComplaintNumber,
      id_number: complaint.idNumber,
      text: complaint.text,
    };

    axios
      .post(`${API}/problems`, newComplaint)
      .then((res) => console.log('response', res))
      .catch((err) => {
        setShowId(false);
      });

    setComplaint(complaintInitial);
    setComplaintPhoneMumber('');
    setComplaintModule(false);
    toast.success('Shikoyatingiz yuborildi');
  };

  const handleGetIdSubmit = (e) => {
    e.preventDefault();
    if (!checkUpload) {
      toast.error('Iltimos avval rasmni tasdiqlang!');
      return;
    }

    let newPhoneNumber = (phoneNumber = '998' + +phoneNumber);
    let newSecondPhoneNumber;
    if (secondNumber) {
      newSecondPhoneNumber = secondNumber = '998' + +secondNumber;
    } else {
      newSecondPhoneNumber = 0;
    }
    if (getUserId.region === 'Viloyatni-tanlang') {
      toast.error('Iltimos viloyatni tanlang');
      return;
    }
    let newGetId = {
      first_name: getUserId.firstName,
      last_name: getUserId.lastName,
      phone_number: +newPhoneNumber,
      second_number: +newSecondPhoneNumber,
      region: getUserId.region,
      file_id: fileData.id,
    };

    axios
      .post(`${API}/identificators`, newGetId)
      .then((res) => {
        setUserData(res.data.data);
        setShowId(true);
      })
      .catch((err) => {
        setShowId(false);
        toast.error(
          `Siz kiritgan ikkinchi raqam bazada avvaldan mavjud. sizning ID raqamingiz: ${err.response.data.error.message}`,
          {
            autoClose: 10000,
          }
        );
      });

    setGetUserId({ region: 'Viloyatni-tanlang' });
    setGetUserId(getIdInitial);
    setPhoneNumber('');
    setSecondNumber('');
    setFileId(null);
    setIdModule(false);
  };

  const uploadImgae = () => {
    const formData = new FormData();
    formData.append('file', fileId);
    axios
      .post(`${API}/file/upload-once`, formData)
      .then((res) => {
        setFileData(res.data.data);
        toast.success('Rasmingiz Tasdiqlandi');
        setCheckUpload(true);
      })
      .catch((err) => {
        setCheckUpload(false);
        toast.info("Rasm tasdiqlanmadi! Rasm hajmi kichikroq bo'lishi kerak!", {
          autoClose: 7000,
        });
      });
  };

  let cities = CITY_ITEMS.map((el, inx) => (
    <option key={inx} value={el}>
      {el}
    </option>
  ));

  return (
    <>
      <GoGift className='gift-1' />
      <GoGift className='gift-2' />
      <GoGift className='gift-3' />
      <LoadingAnim />
      <div className='main'>
        <div className={`customer__id ${showId ? 'show__id hide__buttons' : ''}`}>
          <button
            onClick={() => setShowId(false)}
            className='close__id__module'
          >
            <FaXmark />
          </button>
          <h1>Sizning ID yingiz</h1>
          <h2>
            艾热 <br />
            13794002001 <br />
            广东省佛山市南海区胜利工业区二十九号
          </h2>
          <p>ID: RMR-{userData.id_number}</p>
          <h4>Ununib qo'ymaslik uchun xoziroq saqlab qo'ying</h4>
        </div>
        <div className={`main__box ${idModule || complaintModule || priceModule || branchModule ? 'hide__buttons' : ''}`}>
          <button
            className={`main__id__btn`}
            onClick={() => setIdModule(true)}
          >
            ID olish
          </button>
          <button
            className={`main__complaint__btn`}
            onClick={() => setComplaintModule(true)}
          >
            Shikoyat qoldirish
          </button>
          <div className="main__box__bottom">
            <button
              onClick={() => setPriceModule(true)}
              className={`main__price__btn`}>
              Narxlar
            </button>
            <button
              onClick={() => setBranchModule(true)}
              className={`main__branch__btn`}>
              Filiallar
            </button>
          </div>
          <div className="main__box__socialmedia">
            <h3>Biz bilan bog'lanish</h3>
            <div className="main__box__socialmedia__item">
              <a href="https://www.instagram.com/ishonchli_arzon_kargo?igsh=MWIzeTBkMnh0NXRsMg%3D%3D" target="_blank" rel="noreferrer">
                <FaInstagram />
                Instagram
              </a>
            </div>
            <div className="main__box__socialmedia__item">
              <a href="https://t.me/Ishonchli_arzon_kargo_rasmiy" target="_blank" rel="noreferrer">
                <FaTelegram />
                Telegram
              </a>
            </div>
            <div className="main__box__socialmedia__item">
              <a href="tel:+998944999454">
                <FaPhone />
                +998-94-499-94-54
              </a>
            </div>
            <div className="main__box__socialmedia__item">
              <a href="tel:+998200077334">
                <FaPhone />
                +998-20-007-73-34
              </a>
            </div>
            <div className="main__box__location">
              <h3>
                <CiLocationOn />
                Bizning Manzil
              </h3>
              <p>TOSHKENT SHAHAR
                CHILONZOR TUMANI
                10-MAVZE 25 DOM</p>
              <h4>10:00 DAN 17 00 gacha ochiq</h4>
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={() => {
          setIdModule(false);
          setComplaintModule(false);
          setPriceModule(false);
          setBranchModule(false);
        }}
        className={`overlay ${idModule || complaintModule || priceModule || branchModule || showId ? 'show__overlay' : ''
          }`}
      ></div>

      {/* ID box */}

      <div className={`take__id__box ${idModule ? 'show__id__module' : ''}`}>
        <button
          type='button'
          onClick={() => setIdModule(false)}
          className='close__id__module'
        >
          <FaXmark />
        </button>
        <form onSubmit={handleGetIdSubmit}>
          <div className='form__top'>
            <div className='form__top__inp__box'>
              <label htmlFor='first__name'>Ism*</label>
              <input
                required
                value={getUserId.firstName}
                onChange={(e) =>
                  setGetUserId((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }))
                }
                id='first__name'
                type='text'
              />
            </div>
            <div className='form__top__inp__box'>
              <label htmlFor='last__name'>Familiya*</label>
              <input
                required
                value={getUserId.lastName}
                onChange={(e) =>
                  setGetUserId((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
                id='last__name'
                type='text'
              />
            </div>
          </div>
          <div className='main__inp__box'>
            <label htmlFor='phone'>Telefon Raqam*</label>
            <div>
              <span>+998</span>
              <input
                required
                maxLength={9}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                id='phone'
                type='text'
                pattern='\d*'
              />
            </div>
          </div>
          <div className='main__inp__box'>
            <label htmlFor='phone2'>Qo'shimga Telefon Raqam</label>
            <div>
              <span>+998</span>
              <input
                type='text'
                pattern='\d*'
                maxLength={9}
                value={secondNumber}
                onChange={(e) => setSecondNumber(e.target.value)}
                id='phone2'
              />
            </div>
          </div>
          <div className='main__inp__box'>
            <label htmlFor='image'>Passport rasm*</label>
            <input
              required
              id='image'
              type='file'
              onChange={handleFileChange}
            />
            <button
              onClick={uploadImgae}
              className='confirm__img__btn'
              type='button'
            >
              Rasmni tasdiqlash
            </button>
          </div>
          <select
            onChange={(e) =>
              setGetUserId((prev) => ({ ...prev, region: e.target.value }))
            }
            className='main__inp__box'
          >
            <option required value='Viloyatni-tanlang'>
              Viloyatni tanlang*
            </option>
            {cities}
          </select>
          <button type='submit' className='id__olish'>
            ID olish
          </button>
        </form>
      </div>

      {/* COMPLAINT BOX */}

      <div className={`complaint__box ${complaintModule ? 'show__complaint__box' : ''
        }`}
      >
        <button
          onClick={() => setComplaintModule(false)}
          className='close__id__module'
        >
          <FaXmark />
        </button>
        <form onSubmit={handleComplaintSubmit}>
          <div className='form__top'>
            <div className='form__top__inp__box'>
              <label htmlFor='first__name'>Ism</label>
              <input
                required
                value={complaint.firstName}
                onChange={(e) =>
                  setComplaint((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }))
                }
                id='first__name'
                type='text'
              />
            </div>
            <div className='form__top__inp__box'>
              <label htmlFor='last__name'>Familiya</label>
              <input
                required
                value={complaint.lastName}
                onChange={(e) =>
                  setComplaint((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
                id='last__name'
                type='text'
              />
            </div>
          </div>
          <div className='main__inp__box'>
            <label htmlFor='phone'>Telefon</label>
            <div>
              <span>+998</span>
              <input
                className='complaint__phone__inp'
                required
                maxLength={9}
                value={complaintPhoneMumber}
                onChange={(e) => setComplaintPhoneMumber(e.target.value)}
                id='phone'
                type='tel'
              />
            </div>
          </div>
          <div className='main__inp__box'>
            <label htmlFor='product__id'>ID</label>
            <input
              required
              value={complaint.idNumber}
              onChange={(e) =>
                setComplaint((prev) => ({ ...prev, idNumber: e.target.value }))
              }
              id='product__id'
              type='text'
            />
          </div>
          <div className='main__inp__box'>
            <label htmlFor='product__id'>Shikoyat yozish</label>
            <textarea
              name=''
              id=''
              cols='30'
              required
              value={complaint.text}
              onChange={(e) =>
                setComplaint((prev) => ({ ...prev, text: e.target.value }))
              }
              rows='3'
            ></textarea>
          </div>
          <button className='send__btn'>Yuborish</button>
        </form>
      </div>

      {/* PRICE MODULE */}

      <div className={`price__box ${priceModule ? "show__price__module" : ""}`}>
        <button
          type='button'
          onClick={() => setPriceModule(false)}
          className='close__id__module'
        >
          <FaXmark />
        </button>
        <h2 className="price__box__title">Kargo narxi</h2>
        <ul>
          <li>
            <IoAirplaneSharp />
            <p>Avia 10$ 4 - 9 kun</p>
          </li>
          <li>
            <PiTruckLight />
            <p>Avto 8,8$ 7-15 kun</p>
          </li>
          <li>
            <p>Aviada Taʼriflaringiz qanday?</p>
          </li>
          <li>
            <h4>Javob:</h4>
          </li>
          <li>
            <p>Bizda May oyida boshlab avia kargo 5-9 kunlik narx</p>
          </li>
          <li>
            <p>1- Oddiy yuklar - 10$</p>
            <FaTicket />
          </li>
          <li>
            <p>2- Brend yuklar - 11$</p>
            <FaTicket />
          </li>
          <li>
            <p>3- Seriya yuklar 10,8$ </p>
            <FaTicket />
          </li>
          <li>
            <p>Avto kargo narxi barcha yuklar uchun</p>
          </li>
          <li>
            <p>1kg - 8,8$ (7-15 kun)📆</p>
          </li>
        </ul>
      </div>

      {/* BRANCH MODULE */}

      <div className={`branch__box ${branchModule ? "show__branch__module" : ""}`}>
        <button
          type='button'
          onClick={() => setBranchModule(false)}
          className='close__id__module'
        >
          <FaXmark />
        </button>
        <h3 className="price__box__title">Bizning Filiallar</h3>
        <ul>
          <li>
            <TiTick />
            <a href="https://t.me/muslima_7171" target='_blank' rel="noreferrer">
              Olmazor
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Irgasheva_05" target='_blank' rel="noreferrer">
              Sergili-Oʻzgarish
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/sergeliadmin" target='_blank' rel="noreferrer">
              Sergili-Indeks
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Alyona7374" target='_blank' rel="noreferrer">
              Sebzor
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/muzaffarovna001" target='_blank' rel="noreferrer">
              Yakkasaroy
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/murotboyevvv" target='_blank' rel="noreferrer">
              Yunusobod
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Rasmaddilmir_cargo_admin" target='_blank' rel="noreferrer">
              Chilonzor
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/LMFMB" target='_blank' rel="noreferrer">
              Ohangaron
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/rmr_angren" target='_blank' rel="noreferrer">
              Angren
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Mehribonu_Oltiboyeva" target='_blank' rel="noreferrer">
              Yashnabod
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Boymurod01" target='_blank' rel="noreferrer">
              Mirobod
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Tolqinboyevnaaa" target='_blank' rel="noreferrer">
              Uchtepa
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/nora_xasanova1" target='_blank' rel="noreferrer">
              Bektemir
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Iamfarhadovna" target='_blank' rel="noreferrer">
              Mirzo ulugbek
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Noz1_wop" target='_blank' rel="noreferrer">
              Keles
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Ummu_sofia" target='_blank' rel="noreferrer">
              Zangiota
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/uz_korean_tv" target='_blank' rel="noreferrer">
              Farg'ona filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Sirdaryo_Adminka" target='_blank' rel="noreferrer">
              Sirdaryo filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/RASMADDILMIR_Qashqadaryo_admin" target='_blank' rel="noreferrer">
              Qarshi FILIALIMIZ
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/uz_korean_tv" target='_blank' rel="noreferrer">
              NAMANGAN FILIAL
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/JasurMunis" target='_blank' rel="noreferrer">
              JIZZAH FILIALI
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/baxshyuyllayeva_X" target='_blank' rel="noreferrer">
              BUXORO Filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/JasurMunis" target='_blank' rel="noreferrer">
              Samarqand filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/TMMUMAX" target='_blank' rel="noreferrer">
              Xorazm filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/alonemoon_27" target='_blank' rel="noreferrer">
              Navoiy viloyati filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/RASMADDILMIR_Qashqadaryo_admin" target='_blank' rel="noreferrer">
              Surxondaryo viloyati filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Magistir96" target='_blank' rel="noreferrer">
              Chirchiq shahar filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/Qoraqamish_adminka" target='_blank' rel="noreferrer">
              Qoraqamish filiali
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/IAK_andijon_adminka" target='_blank' rel="noreferrer">
              Andijon shahri fillal
            </a>
          </li>
          <li>
            <TiTick />
            <a href="https://t.me/kamoshaxon98" target='_blank' rel="noreferrer">
              Yaypan fillia
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Home;
