import React, { useEffect, useState } from 'react'
import "./Complaint.scss"
import axios from '../../../api'

const API = "https://api.uzbcargo.uz/api"

function Complaint() {
    const [data, setData] = useState([])
    useEffect(() => {
        axios.get(`${API}/problems`)
            .then(res => {
                setData(res.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    console.log(data);

    let user = data?.map((el) => (
        <div key={el.id} className="complaint__card">
            <h1 className='complaint__fullname'>{el.first_name} {el.last_name}</h1>
            <h2 className='complaint__phone'>+{el.phone_number}</h2>
            <h5 className='complaint__id'>ID: {el.id_number}</h5>
            <p className='complaint__text'>{el.text}</p>
        </div>
    ))

    return (
        <>
            <div className='complaint__wrapper'>
                {user}
            </div>
        </>
    )
}

export default Complaint