import React, { useState } from 'react'
import "./LoadingAnim.scss"

import { FaTruckFast } from "react-icons/fa6";

function LoadingAnim() {
    const [anim, setAnim] = useState(false)
    const [removeAnim, setRemoveAnim] = useState(false)

    setTimeout(() => {
        setAnim(true)
    }, [1000])

    setTimeout(() => {
        setRemoveAnim(true)
    }, [3000])
    return (
        <>
            <div className={`loading ${removeAnim ? "remove__animation" : ""}`}>
                <div className="loading__box">
                    <div className="animation__icon__box">
                        <FaTruckFast />
                    </div>
                    <h1 className={`animation__title ${anim ? "title__show" : ""}`}>
                        Cargo
                    </h1>
                </div>
            </div>
            <div className={`loading__mini ${removeAnim ? "remove__animation__mini" : ""}`}>
                <div className="loading__box__mini">
                    <div className="animation__icon__box__mini">
                        <FaTruckFast />
                    </div>
                    <h1 className={`animation__title__mini ${anim ? "title__show__mini" : ""}`}>
                        Cargo
                    </h1>
                </div>
            </div>
        </>
    )
}

export default LoadingAnim