import React, { useEffect, useState } from 'react'
import "./Login.scss"

import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../api';

const API = "https://api.uzbcargo.uz/api"

function Login() {
    const [showPassword, setShowPassword] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate()

    const handleSumbit = (e) => {
        e.preventDefault()
        let user = {
            login: username,
            password: password
        }
        axios
            .post(`${API}/auth/login`, user)
            .then(res => {
                console.log(res);
                let token = res.data.data.token
                localStorage.setItem("token", token)
                navigate("/admin")
            })
            .catch(err => {
                console.log(err);
                toast.error("Username yoki parol xato!")
            })
    }


    // axiosInstance.get('/identificators')
    //     .then(response => {
    //         console.log('Response:', response.data);
    //     })
    //     .catch(error => {
    //         console.error('Error:', error);
    //     });



    return (
        <div className="login">
            <div className='login__box'>
                <form onSubmit={handleSumbit}>
                    <h1>Login</h1>
                    <input
                        required
                        onChange={e => setUsername(e.target.value)}
                        value={username}
                        type='text'
                        placeholder='Username'
                    />
                    <div className="password__inp__box">
                        <input
                            required
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            type={showPassword ? "text" : "password"}
                            placeholder='Password'
                        />
                        {
                            password &&
                            <button type='button' onClick={() => setShowPassword(prev => !prev)}>
                                {showPassword ? <FaEyeSlash /> : <FaRegEye />}
                            </button>
                        }
                    </div>
                    <button className='login__btn' type='submit'>Login</button>
                </form>
            </div>
        </div>
    )
}

export default Login