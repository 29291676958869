import React, { useState } from 'react'
import "./Admin.scss"
import { FaArrowLeft } from "react-icons/fa6";
import { FaBars } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'

function Admin() {
    const navigate = useNavigate()
    const [toggle, setToggle] = useState(false)
    return (
        <div className='admin'>
            <div className="admin__navbar">
                <h1>Admin</h1>
                <button onClick={() => setToggle(p => !p)}>
                    {
                        toggle ? <FaXmark /> : <FaBars />
                    }
                </button>
            </div>
            <div className={`admin__navbar__katalog ${toggle ? "show__katalog" : ""}`}>
                <NavLink to="users-id">Foydalanuvchilar ID</NavLink>
                <NavLink to="complaint">Shikoyatlar</NavLink>
            </div>
            <div className="admin__sidebar">
                <ul className='admin__collection'>
                    <h2>Admin</h2>
                    <li>
                        <NavLink className="admin__links" to="users-id">
                            Foydalanuvchilar ID
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="admin__links" to="complaint">
                            Shikoyatlar
                        </NavLink>
                    </li>
                </ul>
                <button onClick={() => navigate("/")} className="go__home">
                    <FaArrowLeft />
                    Asosiyga o'tish
                </button>
            </div>
            <div className="admin__content">
                <Outlet />
            </div>
        </div>
    )
}

export default Admin